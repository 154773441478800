<template>
    <div style="background-color: #F7F8F9;height: 100%">
        <div >
            <myAccountBook></myAccountBook>
        </div>
        <div class="footer">
            <van-button slot="button" color="#3F7C53" type="info" @click="accountBook">{{footerTitle}}</van-button>
        </div>
    </div>
</template>

<script>
    import myCustomer from "./stationAgentsubviews/myCustomer";
    import myAccountBook from "./stationAgentsubviews/myAccountBook";
    import {getDictByPage} from "../../utils/api";
    import common from "../../utils/common";
    import {uwStatisticAction} from "../../utils/util";
    export default {
        name: "accountBook",
        components:{
            myCustomer,
            myAccountBook
        },
        data(){
            return{
                active:0,
                footerTitle:'记一笔+'
            }
        },
        methods:{
            accountBook(){
                // if (this.active == 0){
                //     this.$router.push({name: 'addCustomer'})
                // } else {
                    this.$store.dispatch('keepAliveState/addKeepAliveAction', 'addTransacrionDetails')
                    this.$router.push({name:'addTransacrionDetails'})
                // }
            },
        },
        watch:{
            active(newValue){
                this.footerTitle = newValue == 0 ? '新增联系人+' : '记一笔+'
            }
        },
        mounted() {
            uwStatisticAction('/accountBook','小账本')

        },
        beforeRouteLeave (to, from, next) {
            if ( to.name == 'customerDetail' || to.name == 'transactionDetails' || to.name == 'addCustomer'|| to.name == 'addTransacrionDetails'  ){
            } else {
                this.$store.dispatch('keepAliveState/removeKeepAlive', 'accountBook')
            }
            next()
        },
    }
</script>

<style lang="less" scoped>
    .footer{
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 0.88rem;
        .van-button{
            width: 100%;
        }
    }


</style>
