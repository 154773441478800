
<template>
    <div class="bg">
        <van-pull-refresh v-model="isLoading" @refresh="onRefresh" class="bg">
            <div>
<!--                <div class="search">-->
<!--                    <van-search-->
<!--                            v-model="searchValue"-->
<!--                            show-action-->
<!--                            label=""-->
<!--                            left-icon=""-->
<!--                            placeholder="请输入客户姓名"-->
<!--                            @search="onSearch">-->
<!--                        <template #action>-->
<!--                            <div @click="onSearch" class="search-btn">查询</div>-->
<!--                        </template>-->
<!--                    </van-search>-->

<!--                </div>-->

                <div class=" table" >
                        <div class="">
                            <van-list style="margin-bottom: 0.88rem;background-color: #F7F8F9" :error.sync="error"
                                      error-text="请求失败，点击重新加载"
                                      v-model="loading"
                                      :finished="finished"
                                      finished-text="没有更多了"
                                      @load="onLoad"
                                      >
                            <myTransactionsCell
                                    :class="item % 5 != 0?'info':'time'"
                                    v-for="(item,index) in infoList"
                                    :key="index"
                                    :info="item"
                                    :tranType="tranType"
                                    @click.native="transactionDetails(item)">
                            </myTransactionsCell>
                            </van-list>
                        </div>
                </div>
            </div>
        </van-pull-refresh>

    </div>
</template>

<script>
    import myTransactionsCell from "../stationAgentsubviews/myTransactionsCell";
    import {Toast} from "vant";
    import {getAccountBook,getDictByPage} from '@/utils/api'
    import common from "../../../utils/common";
    export default {
        components: {
            myTransactionsCell
        },
        name: "myAccountBook",
        data() {
            return {
                searchValue:'',
                infoList:[],
                isLoading: false,
                loading: false,
                finished: false,
                pageIndex: 1,
                error:false,
                listHeight:null,
                pageSize:'10',
                tranType:[]
            }
        },
        mounted() {
            var h = document.documentElement.clientHeight || document.body.clientHeight;
            this.listHeight = h - ((0.88 *2 + 1.2) *( h / 7.5)) -100
            this.getPageInfo();
        },
    // activated () {
    //     this.onRefresh();
    // },
        methods: {
            getPageInfo () {
                this.tranType = JSON.parse(common.getItem('tranType'))
            },
            onSearch(){
                this.onRefresh();
            },
            transactionDetails(item) {
                this.$router.push({name:'transactionDetails',query:{'transactionID':item.id}})
            },
            add() {
                console.log('add')
            },
            getAccountPageInfo () {
                getDictByPage('accountPage').then(res => {
                    if (res.code === 200) {
                        let data
                        if (res.encryptedStatus === '1') {
                            data = JSON.parse(common.decrypt(res.data, res.timestamp));
                        } else {
                            data = res.data
                        }
                        if (data.businessCode === 200) {
                            common.setItem('tranType',JSON.stringify(JSON.parse(data.data).tranType))
                            this.toGetAccountBook()
                        }
                    }
                })
            },
            toGetAccountBook(){
                getAccountBook({
                    'pageIndex':this.pageIndex.toString(),
                    'pageSize':this.pageSize
                }).then(res=>{
                    // 加载状态结束
                    this.loading = false;
                    if (res.code == 200){
                        if (res.data.businessCode && res.data.businessCode === 200) {
                            this.finished = !res.data.pageModel.hasNextPage;

                            if (this.pageIndex == 1){
                                this.infoList = res.data.pageModel.list;
                            } else {
                                this.infoList = this.infoList.concat(res.data.pageModel.list);
                            }
                            if (!this.finished){
                                this.pageIndex++;
                            }
                        }else {
                            this.error = true;
                        }
                    }else {
                        this.error = true;
                    }
                }).catch(()=>{
                    this.error = true;
                })
            },
            onLoad() {
                if (common.getItem('tranType') == undefined){
                    this.getAccountPageInfo()
                } else {
                    this.toGetAccountBook();
                }

            },
            onRefresh() {
                this.infoList=[];
                this.isLoading = false;
                this.loading = true;
                this.finished=false;
                this.error = false;
                this.pageIndex = 1;
                this.onLoad();
            }

        },
        activated() {
            console.log('activated')
            this.onRefresh();
        }
    }
</script>

<style lang="less" scoped>
    .bg {
        background: #F7F8F9;
        .flex {
            display: flex;
        }
        .footer {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 0.88rem;
            .van-button {
                width: 100%;
            }
        }
        .search {
            margin: 0 0.2rem;
            background: #eeeeee;
            padding-top: 0.2rem;
            height: 1.2rem;
        }
        .search-btn {
            color: #3F7C53
        }
        .table {
            margin: 0.2rem 0.2rem 0px 0.2rem;
            background: #eeeeee
        }
    }
</style>
