<template>
    <div class="bg">
        <van-pull-refresh v-model="isLoading" @refresh="onRefresh" >
            <div >
                <div class="search">
                    <van-search
                            v-model="searchValue"
                            show-action
                            label=""
                            left-icon=""
                            placeholder="请输入客户姓名"
                            @search="onSearch"
                    >
                        <template #action>
                            <div @click="onSearch" class="search-btn">查询</div>
                        </template>
                    </van-search>
                </div>
                <div class="van-clearfix table" >
                        <div class="van-clearfix">
                            <div class="header">
                                <van-row >
                                    <van-col span="7"><p  class="cell-title">分组</p></van-col>
                                    <van-col span="7"><p  class="cell-title">姓名</p></van-col>
                                    <van-col span="10"><p class="cell-title">电话号码</p></van-col>
                                </van-row>
                            </div>
                            <van-list style="margin-bottom: 0.88rem;z-index: 9999"
                                      :error.sync="error"
                                      error-text="请求失败，点击重新加载"
                                      v-model="loading"
                                      :finished="finished"
                                      finished-text="没有更多了"
                                      @load="onLoad">
                                <myCustomerCell
                                        v-for="(item,index) in infoList"
                                        :key="index"
                                        class="info"
                                        :info="item"
                                        :customerGroup="customerGroup"
                                        @click.native="customerDetail(item)">
                                </myCustomerCell>
                            </van-list>
                        </div>
                </div>
            </div>
        </van-pull-refresh>

    </div>
</template>

<script>
    import myCustomerCell from "../stationAgentsubviews/myCustomerCell";
    import {Toast} from "vant";
    import {getDictByPage,getCustomerList} from "../../../utils/api";
    import common from "../../../utils/common";
    export default {
        components: {
            myCustomerCell
        },
        name: "myCustomer",
        data() {
            return {
                is:false,
                searchValue:'',
                infoList:[],
                isLoading: false,
                loading: false,
                finished: false,
                pageIndex: 1,
                pageSize:'10',
                error:false,
                listHeight:null,
                customerGroup:[]
            }
        },
        mounted() {
            var h = document.documentElement.clientHeight || document.body.clientHeight;
            this.listHeight = h - (0.88 *2 *( h / 7.5))
            this.getPageInfo();
        },
        methods: {
            getPageInfo () {
                this.customerGroup = JSON.parse(common.getItem('customerGroup'));
            },
            customerDetail(item){
                this.$router.push({name:'customerDetail',query:{'customerNo':item.customerNo}})
            },
            onSearch(){
                console.log('search')
                this.onRefresh();
            },
            showDetail() {
                console.log('detail')
            },
            add() {
                console.log('add')
            },
            toGetCustomerList(){
                getCustomerList({
                    'name':this.searchValue,
                    'pageIndex':this.pageIndex.toString(),
                    'pageSize':this.pageSize
                }).then(res=>{
                    // 加载状态结束
                    this.loading = false;
                    if (res.code == 200){
                        if (res.data.businessCode && res.data.businessCode === 200) {
                            this.finished = !res.data.pageModel.hasNextPage;

                            if (this.pageIndex == 1){
                                this.infoList = res.data.pageModel.list;
                            } else {
                                this.infoList = this.infoList.concat(res.data.pageModel.list);
                            }
                            if (!this.finished){
                                this.pageIndex++;
                            }
                        }else {
                            this.error = true;
                        }
                    }else {
                        this.error = true;
                    }
                }).catch(()=>{
                    this.error = true;
                })
            },
            onLoad() {
                console.log("onLoad")
                this.toGetCustomerList();
            },
            onRefresh() {
                console.log('onRefresh')
                this.infoList=[];
                this.isLoading = false;
                this.loading = true;
                this.finished=false;
                this.error = false;
                this.pageIndex = 1;
                this.onLoad();

            }
        },
        activated() {
            this.onRefresh();
        }
    }
</script>

<style lang="less" scoped>
    .bg {
        background: #eeeeee;
        margin: 0;
        padding: 0;
        height: 100%;
        .flex {
            display: flex;
        }
        .footer {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 0.88rem;
            .van-button {
                width: 100%;
            }
        }
        .search {
            margin: 0 0.2rem;
            background: #eeeeee;
            padding-top: 0.2rem
        }
        .search-btn {
            color: #3F7C53
        }
        .table {
            margin: 0 0.2rem 0px 0.2rem;
            background: #eeeeee;
            height: 100%;
            .header {
                margin: 0 0.2rem;
                height: 1rem
            }
            .cell-title {
                text-align: left;
                width: 1.2rem;
                height: 0.5rem;
                line-height: 0.5rem;
                margin-top: 0.4rem;
                border-radius: 0.05rem;
                color: black
            }
        }
    }

</style>
