<template>
    <div class="bg">
        <div  class="cell-bg flex justify_content_space_between align_items_center">
            <div class="type-label">{{dictValue}}</div>
            <div class="flex align_items_center">
                <div class="value-label ">{{info.tranAmount}}</div>
                <van-icon name="arrow" size="20px" color="#CCCCCC" class="arrow"/>
            </div>
        </div>
        <div class="line">
        </div>
    </div>
</template>

<script>
    import {getDictByPage} from "@/utils/api";
    export default {
        name: "myTransactionsCell",
        props:['info','tranType'],
        filters: {
            ellipsis (value) {
                if (!value) return ''
                if (value.length > 5) {
                    return value.slice(0,5) + '...'
                }
                return value
            }
        },
        data(){
            return {
                dictValue:''
            }
        },
        mounted() {
            // this.toGetDictValue(this.info.tranType)
            this.getAccountPageInfo()
        },
        methods:{
          getAccountPageInfo () {
                getDictByPage('accountPage').then(res => {
                    if (res.code === 200) {
                        let data
                        if (res.encryptedStatus === '1') {
                            data = JSON.parse(common.decrypt(res.data, res.timestamp));
                        } else {
                            data = res.data
                        }
                        if (data.businessCode === 200) {
                            for (var item of JSON.parse(data.data).tranType){
                              if (this.info.tranType == item.dictKey){
                                  this.dictValue = item.dictValue;
                                }
                            }
                        }
                    }
                })
            },
            toGetDictValue(dictKey){
                for (var item of this.tranType){
                    if (dictKey == item.dictKey){
                        this.dictValue = item.dictValue;
                    }
                }
            },
        }
    }
</script>

<style lang="less" scoped>
    .bg{
        .time{
            height: 50px;
            line-height: 50px;
            color: black;
            background: #dddddd;
        }
        .info {
            height: 50px;
            line-height: 50px;
            background-color: white;
        }
        .cell-bg{
            margin: 0 0.2rem;
        }
        .name-label{
            text-align: left
        }
        .type-label{
            text-align: left;
            font-size: 14px;
            color: #999999;
        }
        .value-label{
            text-align: right;
            font-weight: bold;
            font-size: 14px;
            color: #272829;
        }
        .arrow{
            margin-left: 10px
        }
        .line{
            height: 0.5px;
            background-color: #eeeeee;
            margin-top: -1px;
            margin-left: 0.2rem;
            margin-right: 0.2rem
        }
    }
</style>

